import {
  IEncodedLocationSearchRequest,
  SearchQueryParams,
  IDecodeSearchPath,
  IEncodeSearchPath,
} from './location.types';
import { encodeSearchRequest } from './params/encodeSearchRequest';
import { decodeSearchRequest } from './params/decodeSearchRequest';

const PARAM_VALUE_DELIMITER = '-';

export const decodePath: IDecodeSearchPath = path => {
  const rawRequestParams = path
    .split('/')
    .reduce<IEncodedLocationSearchRequest>((result, param) => {
      const [key, ...value] = param.split(PARAM_VALUE_DELIMITER);

      return {
        ...result,
        [key]: value.join(PARAM_VALUE_DELIMITER),
      };
    }, {});

  return decodeSearchRequest(rawRequestParams);
};

const PATH_PARAMS_SORT = [
  SearchQueryParams.Query,
  SearchQueryParams.DocumentType,
  SearchQueryParams.Page,
  SearchQueryParams.SortBy,
];

export const encodePath: IEncodeSearchPath = searchRequest => {
  const params = encodeSearchRequest(searchRequest);

  return Object.keys(params)
    .sort((a: SearchQueryParams, b: SearchQueryParams) => {
      return PATH_PARAMS_SORT.indexOf(a) - PATH_PARAMS_SORT.indexOf(b);
    })
    .map(param => `${param}${PARAM_VALUE_DELIMITER}${params[param]}`)
    .join('/');
};
