import * as React from 'react';

import { st, classes } from './Title.st.css';
import { stripMarkTags } from '../stripMarkTags';
import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';

interface ITitleProps {
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  url?: string;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export class Title extends React.Component<ITitleProps> {
  static contextType = SearchResultsLayoutContext;
  context!: React.ContextType<typeof SearchResultsLayoutContext>;

  render() {
    const commonProps: React.HTMLAttributes<
      HTMLAnchorElement | HTMLSpanElement
    > &
      IDataHookAttribute & { className: any } = {
      title: stripMarkTags(this.props.title),
      'data-hook': 'item-title',
      onClick: this.props.onClick,
      dangerouslySetInnerHTML: { __html: this.props.title },
      className: st(classes.root, {
        mobileView: this.context.isMobile,
        fluid: this.context.isFluid,
      }),
    };

    if (this.props.url) {
      return <a {...commonProps} href={this.props.url} />;
    }

    return <span {...commonProps} />;
  }
}
