import * as React from 'react';

import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { Pagination } from 'wix-ui-tpa/Pagination';

import { SearchResultsLayoutContext } from '../SearchResultsLayoutContext';
import { ISearchResultsProps } from './SearchResults.types';
import { SearchResultsContext } from './SearchResultsContext';
import { SearchInput } from '../SearchInput';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';
import { StatusLine } from '../StatusLine';
import { TabLine } from './TabLine';
import { FailedMessage } from '../ResultsStatus';

import { st, classes } from './SearchResults.st.css';

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

const SearchResultsComponent: React.FunctionComponent<ISearchResultsProps> = ({
  buildPageUrl,
  failed,
  isDemoContent = false,
  isFluid,
  isLoading = false,
  isMobile,
  isPaginationHidden,
  isSearchBarEnabled,
  sortProps,
  tabsAlignment,
  totalCount,
  visibleTabsWithTitles,
  apiErrorDetails,
  ...props
}) => (
  <div
    data-hook="search-results-root"
    className={st(classes.root, { mobileView: isMobile, fluid: isFluid })}
  >
    <TPAComponentsProvider value={{ mobile: isMobile }}>
      <SearchResultsContext.Provider value={{ isMobile }}>
        {isSearchBarEnabled && (
          <SearchInput
            clearLabel={props.searchClearLabel}
            onChange={props.onQueryChange}
            onClear={props.onQueryClear}
            onSubmit={props.onSubmit}
            placeholder={props.searchPlaceholder}
            value={props.searchQuery}
          />
        )}
        <div data-hook="content-wrapper" className={classes.contentWrapper}>
          <div
            data-hook="content"
            className={st(classes.content, { loading: isLoading })}
          >
            {visibleTabsWithTitles.length > 1 && (
              <TabLine
                activeTabIndex={props.activeTabIndex}
                alignment={tabsAlignment}
                items={visibleTabsWithTitles}
                onTabClick={props.onTabChange}
              />
            )}

            {!failed && (
              <StatusLine
                activeTabIndex={props.activeTabIndex}
                isFluid={isFluid}
                isMobile={isMobile}
                searchQuery={props.lastQuery}
                settings={props.settings}
                sortProps={sortProps}
                visibleTabsWithTitles={visibleTabsWithTitles}
              />
            )}

            {failed && (
              <FailedMessage
                isMobile={isMobile}
                apiErrorDetails={apiErrorDetails}
              />
            )}

            {isDemoContent ? (
              <DemoContentNotification
                text={props.demoContentNotificationText}
              />
            ) : null}

            <SearchResultsLayoutContext.Provider
              value={{
                isMobile,
                isFluid,
              }}
            >
              {props.children}
            </SearchResultsLayoutContext.Provider>

            {props.currentPage && props.totalPages && !isPaginationHidden && (
              <div
                className={st(classes.paginationWrapper, {
                  alignment: props.paginationAlignment,
                })}
              >
                <Pagination
                  totalPages={props.totalPages}
                  currentPage={props.currentPage}
                  maxPagesToShow={
                    isMobile
                      ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
                      : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
                  }
                  pageUrl={buildPageUrl}
                  onChange={props.onPageChange}
                  data-hook="pagination"
                />
              </div>
            )}
          </div>
          {isLoading && <Spinner dataHook="spinner" />}
        </div>
      </SearchResultsContext.Provider>
    </TPAComponentsProvider>
  </div>
);

export const SearchResults = SearchResultsComponent;
